import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const fruitData = [
    { name: "Brioso", varieties: "Brioso, Annico, Rexoso, Comoros" },
    { name: "Kazuti", varieties: "Kazuti, Roundita" },
    { name: "Sweeterno", varieties: "Sweeterno, Dunk, 22K951744, 22K952109, Climundo" },
    { name: "Piccolo", varieties: "Piccollo, Maxxiany, Proventura, Gerudo, 1Reihe Testsorten" },
    { name: "Xylia", varieties: "Xylia, 1 Reihe Testsorten" },
    { name: "Avalantino", varieties: "Avalantino" },
    { name: "Xolany", varieties: "Xolany" },
];

const FruitTable = ({ onClose }) => {
    return (
        <TableContainer
            component={Paper}
            style={{
                position: "absolute",
                zIndex: 10,
                background: "white",
                marginTop: "10px",
                width: "max-content",
                left: "0",
                top: "100%",
            }}
        >
            <div style={{ position: "relative" }}>
                <IconButton
                    size="small"
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        right: "2px",
                        top: "2px",
                        zIndex: 11
                    }}
                >
                    <CloseIcon sx={{ width: '15px', height: '15px' }} />
                </IconButton>
                <Table>
                    <TableHead style={{ backgroundColor: "#d3d3d3" }}>
                        <TableRow>
                            <TableCell style={{ fontWeight: "bold", color: "#333", padding: "4px", borderRight: "1px solid #ccc" }}>Name</TableCell>
                            <TableCell style={{ fontWeight: "bold", color: "#333", padding: "4px" }}>Varieties Included</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fruitData.map((fruit, index) => (
                            <TableRow key={index} style={{ height: "10px" }}>
                                <TableCell style={{ padding: "4px", borderRight: "1px solid #ccc" }}>{fruit.name}</TableCell>
                                <TableCell style={{ padding: "4px" }}>{fruit.varieties}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    );
};

export default FruitTable;