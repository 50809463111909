import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FruitTable from './FruitTable';
import InfoIcon from "@mui/icons-material/Info";

function Dropdown(props) {
    const { name, values, selectedValue, handleChange, showColon, sx } = props;
    const [showTable, setShowTable] = useState(false);

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', position: 'relative' }}>
                <InputLabel
                    id={`${name}-label`}
                    sx={{
                        color: '#000',
                        fontWeight: '800',
                        fontFamily: 'Red Hat Display',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                        cursor: 'pointer',
                    }}
                    onClick={() => name === "Fruits" && setShowTable(!showTable)}
                >
                    {name}
                    {name === "Fruits" && (
                        <InfoIcon sx={{ width: '15px', pb: '10px', cursor: 'pointer', color: '#6B6B6B', }} />
                    )}
                </InputLabel>
                {showColon && ':'}
            </div>
            {name === "Fruits" && showTable && <FruitTable onClose={() => setShowTable(false)} />}
            <FormControl variant="standard">
                <Select
                    labelId={`${name}-label`}
                    id={name}
                    value={selectedValue}
                    onChange={handleChange}
                    disableUnderline
                    sx={{
                        '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                        '& .MuiSelect-root': {
                            border: 'none'
                        },
                    }}
                >
                    {values.map((value) => (
                        <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default Dropdown;